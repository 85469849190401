<template>
	<div>
		<div class="statistics container container-l mx-auto">
			<h2>{{ $t("headings.statistics") }}</h2>
			<div class="statistic-container">
				<div
					class="statistic"
					v-for="statistic in statistics"
					:key="statistic.id"
				>
					<div class="content">
						<div class="line">
							<div class="number">
								<span> {{ statistic.count }}</span>
							</div>
						</div>
					</div>
					<h3 class="name">{{ statistic.name }}</h3>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			statistics: [
				{ id: 1, name: "Projects", count: "14" },
				{ id: 2, name: "Industries", count: "10" },
				{ id: 3, name: "Clients", count: "14" },
				{ id: 4, name: "Team member", count: "10" },
				{ id: 5, name: "Countries", count: "5" }
			]
		};
	}
};
</script>
