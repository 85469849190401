<template>
	<div>
		<hero :text="$t('misc.aboutUsDescription')">
			<img alt="Vue logo" src="@/assets/images/hero.svg" />
		</hero>
		<cards />
		<section>
			<our-story />
		</section>
		<whyUs :button="$t('labels.showOurWork')" />
		<section>
			<statistics />
		</section>
	</div>
</template>

<script>
import Cards from "@/components/Cards.vue";
import Hero from "../components/Hero.vue";
import WhyUs from "../components/WhyUs.vue";
import OurStory from "../components/OurStory.vue";
import Statistics from "../components/Statistics.vue";

export default {
	components: {
		Cards,
		Hero,
		WhyUs,
		OurStory,
		Statistics
	}
};
</script>
