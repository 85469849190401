<template>
    <div class="ourstory-bg">
        <div class="container mx-auto my-32 our-story">
            <div class="our-story-head relative z-10">
                <figure class="head-figure hidden lg:block">
                    <img
                        src="@/assets/images/ourstory-img.svg"
                        alt=""
                    />
                </figure>
                <h2 class="title">{{ $t("headings.ourStory") }}</h2>
            </div>

            <div class="story-slider hidden xs:block">
                <vue-slick-carousel
                    v-if="chuncks.length"
                    v-bind="settings"
                    ref="story"
                >
                    <div
                        class="slide"
                        v-for="(chunck, index) in chuncks"
                        :key="index"
                    >
                        <!-- <div>
						<button
							@click="prevSlide()"
							class="slide-button slide-prev"
						></button>
					</div>
					<div><button class="slide-button second"></button></div>
					<div><button class="slide-button is-individual"></button></div>
					<div><button class="slide-button third"></button></div>
					<div>
						<button
							@click="nextSlide()"
							class="slide-button slide-next"
						></button>
					</div> -->
                        <figure class="px-4 hidden lg:block">
                            <img
                                class=""
                                src="@/assets/images/ourstory-path.svg"
                                alt=""
                            />
                        </figure>
                        <figure class="px-4 hidden xs:block lg:hidden sm-figure">
                            <img
                                class=""
                                src="@/assets/images/ourstory-path1.svg"
                                alt=""
                            />
                        </figure>
                        <div
                            v-for="(story, i) in chunck"
                            :key="story.id"
                            class="story story-details"
                            :class="chunck.length === 3 ? 'story-3' : ''"
                            :id="`body-${story.id}-chunck${index}`"
                        >
                            <button
                                class="slide-button"
                                @click="selectStory(story, i, index)"
                            ></button>
                            <div>
                                <h2>{{ story.date }}</h2>
                                <p class="">
                                    {{ story.body.en }}
                                </p>
                            </div>
                        </div>
                    </div>
                </vue-slick-carousel>
            </div>

            <div class="story-list-container block xs:hidden">
                <ul class="story-list">
                    <li
                        v-for="story in stories"
                        :key="story.id"
                        :id="`list-${story.id}`"
                        class="list-item story-details"
                    >
                        <div class="list-item-container">
                            <button
                                class="item-button"
                                @click="selectListStory(story)"
                            ></button>
                            <div class="item-content">
                                <h2 class="date">{{ story.date }}</h2>
                                <p class="discription">
                                    {{ story.body.en }}
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
    data () {
        return {
            settings: {
                dots: false,
                arrows: false,
                dotsClass: "slick-dots custom-dot-class",
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1
            },
            stories: [
                {
                    id: 2,
                    date: "2018",
                    title: {
                        en: "Dicta ipsam id ratione nihil esse dolor at."
                    },
                    body: {
                        en:
                            "Our Creator Mr. Mohamed Nawareg Created the first version of our company in the name of “Solution Plus” for the Saudian Media Group “MediaPlus”"
                    }
                },
                {
                    id: 3,
                    date: "2019",
                    title: {
                        en: "Et nihil perferendis consequuntur nemo et eaque."
                    },
                    body: {
                        en:
                            "Our Creator Mr. Mohamed Nawareg left “Solution Plus” with part of the team to create the new company “Varit”"
                    }
                },
                {
                    id: 4,
                    date: "2020",
                    title: {
                        en: "Et commodi et nobis."
                    },
                    body: {
                        en:
                            "German company “Gabal Group” acquired “Varit” so it’s now one of Gabal Group companies"
                    }
                },
                {
                    id: 5,
                    date: "2021",
                    title: {
                        en: "Est nemo voluptatem voluptatem unde explicabo cupiditate et."
                    },
                    body: {
                        en:
                            "We launched our first big platform “MounTruck” which specialized in Heavy trucks selling"
                    }
                },
                {
                    id: 6,
                    date: "2021",
                    title: {
                        en: "Et nihil perferendis consequuntur nemo et eaque."
                    },
                    body: {
                        en:
                            "Started Our partnership with our beloved partners, “Nash2” and “Teba Group”"
                    }
                },
                // {
                //     id: 7,
                //     date: "2010",
                //     title: {
                //         en: "Et commodi et nobis."
                //     },
                //     body: {
                //         en:
                //             "Minima nulla sed quaerat voluptate quibusdam deserunt in ea. Deleniti accusantium omnis labore rerum. Qui quod eos vero quis necessitatibus ea."
                //     }
                // },
                // {
                //     id: 8,
                //     date: "2001",
                //     title: {
                //         en: "Est nemo voluptatem voluptatem unde explicabo cupiditate et."
                //     },
                //     body: {
                //         en:
                //             "Fugit repellat voluptatibus officiis corporis aut officiis voluptas. Quam omnis id ad quia ea. Reiciendis doloribus dolores quod. Nulla dolorem vel maxime quis."
                //     }
                // },
                // {
                //     id: 9,
                //     date: "2022",
                //     title: {
                //         en: "Est nemo voluptatem voluptatem unde explicabo cupiditate et."
                //     },
                //     body: {
                //         en:
                //             "Fugit repellat voluptatibus officiis corporis aut officiis voluptas. Quam omnis id ad quia ea. Reiciendis doloribus dolores quod. Nulla dolorem vel maxime quis."
                //     }
                // },
                // {
                //     id: 10,
                //     date: "2001",
                //     title: {
                //         en: "Est nemo voluptatem voluptatem unde explicabo cupiditate et."
                //     },
                //     body: {
                //         en:
                //             "Fugit repellat voluptatibus officiis corporis aut officiis voluptas. Quam omnis id ad quia ea. Reiciendis doloribus dolores quod. Nulla dolorem vel maxime quis."
                //     }
                // }
            ],
            chuncks: [],
            chunck: [],
            lastElement: {},
            selected: false
        };
    },
    components: {
        VueSlickCarousel
    },
    mounted () {
        this.setChuncks();
        document
            .getElementById(`list-${this.stories[0].id}`)
            .classList.toggle("selected");
    },
    methods: {
        setChuncks () {
            let chunk = [];
            let obj = {};
            this.stories.map((story, i) => {
                chunk.push(story);
                if (i !== 0 && i % 3 === 0) {
                    obj = story;
                    this.chuncks.push(chunk);
                    chunk = [];
                    chunk.push(obj);
                } else {
                    if (this.stories.length - i === 1) {
                        this.chuncks.push(chunk);
                    }
                }
            });
            setTimeout(() => {
                document
                    .getElementById(`body-${this.chuncks[0][0].id}-chunck0`)
                    .classList.add("selected");
            }, 0);
        },
        prevSlide () {
            this.$refs.story.prev();
        },
        nextSlide () {
            this.$refs.story.next();
        },
        selectStory (story, i, index) {
            const stories = document.querySelectorAll("div.story-details");
            for (let index = 0; index < stories.length; index++) {
                stories[index].classList.remove("selected");
            }
            if (i === 0) {
                this.prevSlide();
                if (index != 0) {
                    index -= 1;
                }
            }
            if (i === 3) {
                this.nextSlide();
                index += 1;
            }
            document
                .getElementById(`body-${story.id}-chunck${index}`)
                .classList.toggle("selected");
        },
        selectListStory (story) {
            const stories = document.querySelectorAll("li.story-details");
            for (let index = 0; index < stories.length; index++) {
                stories[index].classList.remove("selected");
            }
            document.getElementById(`list-${story.id}`).classList.toggle("selected");
        }
    }
};
</script>
