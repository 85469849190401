<template>
	<section class="container-l mt-20">
		<div class="container flex flex-col mx-auto">
			<div class="md:flex-row flex flex-col-reverse">
				<div class="sm:flex w-full mb-6 md:mb-12 items-end">
					<div class="flex-col sm:w-3/5 w-full sm:my-0 my-3 sm:mx-0 mx-3x mt-3">
						<div
							class="sm:flex hidden sm:w-full mb-6 md:mb-0 md:justify-end transform translate-y-1/3 fastanswer1-img"
						>
							<img
								alt="Vue logo"
								src="@/assets/images/fastanswer1.svg"
								class="w-1/2 sm:w-2/3 2xl:w-1/2 mb-6"
							/>
						</div>
						<div
							class="main-radius-1 main-shadow text-left xs:p-11 p-6 md:pt-32 md:mt-0"
						>
							<div class="sm:hidden">
								<img
									alt="Vue logo"
									src="@/assets/images/fastanswer1.svg"
									class="w-1/3"
								/>
							</div>
							<h2 class="text-2xl mt-3">{{ $t("headings.fastanswer1") }}</h2>
							<h2>{{ $t("misc.fastanswer1Desc") }}</h2>
						</div>
					</div>

					<div class="flex sm:w-2/5 w-full md:mx-12 md:ml-12 sm:mx-6 mt-6">
						<div
							class="sm:flex-col flex main-radius-2 main-shadow w-full p-6 sm:pt-20 pt-6"
						>
							<div
								class="sm:h-32 h-full w-1/4 sm:w-full flex fastanswer2-img xl:mb-8 me-4"
							>
								<img
									alt="Vue logo"
									src="@/assets/images/fastanswer2.svg"
									class="tcard"
								/>
							</div>
							<div
								class="h-fullk flex flex-col justify-center ml-auto sm:w-full w-3/4"
							>
								<h2 class="text-2xl">{{ $t("headings.fastanswer2") }}</h2>
								<h2 class="sm:w-full w-3/4">
									{{ $t("misc.fastanswer2Desc") }}
								</h2>
							</div>
						</div>
					</div>
				</div>

				<div
					class="flex flex-col justify-center md:w-1/3 sm:w-1/2 w-full sm:px-0 px-6"
				>
					<div class="my-auto">
						<h2 class="text-2xl mb-3 font-semibold">
							{{ $t("headings.whyUs") }}
						</h2>
						<p>
							{{ $t("misc.whyUsDesc") }}
						</p>
						<router-link
							v-if="button !== null"
							class="flex mt-11 w-full pb-4 pt-4 mx-auto 2xs:w-2/3 rounded-3xl bg-primary-600"
							to="/work"
						>
							<span class="mx-auto text-center w-11/12">
								{{ button }}
							</span>
						</router-link>
					</div>
				</div>
			</div>

			<div
				class="main-img sm:flex-row flex-col flex md:w-4/5 w-full items-center relative sm:mx-0 smx-3 sm:mt-0 smt-3"
			>
				<div
					class="feat-img md:w-1/3 w-5/12 2xl:w-1/4 hidden sm:flex absolute sm:my-0 my-11 transform sm:translate-x-0 translate-x-1/2 md:left-1/2 sm:right-0 left-3/5 ml-11 md:ml-0"
				>
					<img alt="" src="@/assets/images/feat-4.svg" class="w-full" />
				</div>
				<div
					class="main-radius-2 main-shadow flex font-medium md:w-2/3 sm:w-3/4 w-full self-start p-6 xs:px-11 xs:py-6"
				>
					<div
						class="sm:w-3/4 w-full mx-16l xs:mt-4 h-full flex flex-col justify-start mr-0 mb-6"
					>
						<img
							alt=""
							src="@/assets/images/feat-4.svg"
							class="sm:hidden w-5/12"
						/>

						<h2 class="text-2xl font-semibold mt-3">
							{{ $t("headings.fastanswer3") }}
						</h2>
						<p class="">
							{{ $t("misc.fastanswer3Desc") }}
						</p>
					</div>
				</div>
			</div>
			<!-- </div> -->
		</div>
	</section>
</template>

<script>
export default {
	props: {
		button: {
			default: null
		}
	}
};
</script>
<style></style>
